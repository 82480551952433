/* @flow */
import * as React from 'react'
import { Fragment } from 'react'
import cx from 'classnames'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import styles from '../layouts/styles.module.css'

import { SquareContainer, SquareRow, SquareBox } from '../layouts'


const ImgCaseStudyMyer = '../images/myer2.jpeg';
const ImgCaseStudyMX51 = '../images/retail.jpg';
const ImgCaseStudyFaethm = '../images/faethm.jpeg';
const ImgSecomaxMark = '../images/secomax_mark.png';

const IndexPage = (): React.Node => (

<Fragment>
    <section className={cx(styles.hero, styles.secomax_hero)}>
      <div>
        <div>
          <p>
            <strong>Intelligent Systems</strong> for <strong>Modern Retail</strong>.
          </p>
        </div>
      </div>
    </section>

  <section className={cx(styles.home_cta, styles.home_cta_flat)}>
    <div>
      <p>SECOMAX Retail Systems puts your retail business ahead of the competition, <Link to="/contact-us">Request A Brochure Now</Link> to learn how.</p>
    </div>
  </section>
</Fragment>
)


export default IndexPage
